import moment from 'moment/moment'
import Counter from '@/components/Counter/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'player-item',
  props: ['itemData', 'identifier', 'expanded'],
  mixins: [globalHelper],
  components: {
    Counter
  },
  data () {
    return {
      containerId: this.identifier || '',
      visible: this.expanded || false
    }
  },

  computed: {
    age () {
      return this.extractAge(this.itemData.birth_date)
    },
    yellowCards () {
      return !this.hasRedCard() ? this.itemData.cards : 0
    },
    nationality () {
      if (!this.itemData.native_country_id) {
        return this.itemData.native_country_id
      }
      const images = require.context('../../assets/flags/4x3/', false, /\.svg$/)
      return images('./' + this.itemData.native_country_id + '.svg')
    },
    htURL () {
      const localeStr = this.user && this.user.locale ? `/${this.user.locale}` : ''
      return `https://www.hattrick.org/goto.ashx?path=${localeStr}/Club/Players/Player.aspx?playerId=${this.itemData.id}`
    }
  },

  methods: {
    hasRedCard () {
      return this.itemData.cards && this.itemData.cards > 2
    },
    hasBruise () {
      return this.itemData.injury_level !== null && this.itemData.injury_level === 0
    },
    extractAge (value) {
      if (!value) {
        return null
      }
      const bday = moment.utc(value)
      if (!bday.isValid()) {
        return null
      }

      const now = moment.utc()
      const days = now.diff(bday, 'days')

      return {
        years: Math.floor(days / 112),
        days: days % 112
      }
    },
    opener () {
      // bypass Chrome redirect caching
      const ts = moment().valueOf()
      window.open(this.htURL + `&ts=${ts}`, '_blank')
    }
  }
}
