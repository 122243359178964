import PlayerItem from '@/components/PlayerItem/main'
import PopUp from '@/components/PopUp/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'player-bookmarked-item',
  components: {
    PlayerItem,
    PopUp
  },
  props: ['itemData'],
  mixins: [globalHelper],
  data () {
    return {
      canUnbookmarkPlayer: false
    }
  },

  computed: {
  },

  methods: {
    unbookmark (event) {
      event.preventDefault()
      event.stopPropagation()

      // show the pop-up
      this.canUnbookmarkPlayer = true
    },
    confirmRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // perform the un-bookmarking and close the tooltip
      this.canUnbookmarkPlayer = false

      this.$store.dispatch('players_bookmark', { id: this.itemData.id, bookmarked: false }).then(
        (response) => {
        },
        response => this.handleErrors(response)
      )
    },
    cancelRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // close popup
      this.canUnbookmarkPlayer = false
    }
  }
}
