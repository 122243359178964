import get from 'lodash.get'
import FilterItem from '@/components/FilterItem/main'
import PlayerBookmarkedItem from '@/components/PlayerBookmarkedItem/main'
import PlayerOwnItem from '@/components/PlayerOwnItem/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'home',
  components: {
    FilterItem,
    PlayerBookmarkedItem,
    PlayerOwnItem
  },
  mixins: [globalHelper],
  data () {
    return {
      loadingFilters: false,
      loadingBookmarks: false,
      loadingPlayers: false
    }
  },

  created () {
    this.getMyPlayers()

    this.getMyBookmarks()

    this.getMyFilters()
  },
  computed: {
    players_own () {
      return this.$store.state.players_own
    },
    players_bookmarked () {
      return this.$store.state.players_bookmarked
    },
    filters () {
      return this.$store.state.filters
    },
    maxFilters () {
      return get(this, 'user.max_filters', null) || null
    },
    canAddFilter () {
      return this.maxFilters === null || !this.filters || this.filters.length < this.maxFilters
    }
  },

  methods: {
    getMyPlayers () {
      if (this.loadingPlayers) {
        return
      }

      this.loadingPlayers = true
      this.$store.dispatch('players_get_own').then(
        (response) => {
          this.loadingPlayers = false
        },
        (response) => {
          this.loadingPlayers = false
          this.handleErrors(response)
        }
      )
    },
    getMyBookmarks () {
      if (this.loadingBookmarks) {
        return
      }

      this.loadingBookmarks = true
      this.$store.dispatch('players_get_bookmarked').then(
        (response) => {
          this.loadingBookmarks = false
        },
        (response) => {
          this.loadingBookmarks = false
          this.handleErrors(response)
        }
      )
    },
    getMyFilters () {
      if (this.loadingFilters) {
        return
      }

      this.loadingFilters = true
      this.$store.dispatch('filters_get').then(
        (response) => {
          this.loadingFilters = false
        },
        (response) => {
          this.loadingFilters = false
          this.handleErrors(response)
        }
      )
    },
    addFilter () {
      if (this.canAddFilter) {
        this.$router.push({ name: 'FiltersAddEdit' })
      }
    }
  }
}
