import moment from 'moment/moment'
import PlayerItem from '@/components/PlayerItem/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'player-own-item',
  components: {
    PlayerItem
  },
  props: ['itemData'],
  mixins: [globalHelper],
  data () {
    return {
      promotion_status: false,
      promoting: false
    }
  },

  computed: {
    promoted () {
      return this.promotion_status || this.itemData.promoted
    }
  },

  methods: {
    canPromote () {
      return moment.utc(this.itemData.transfer_deadline).diff(moment.utc(), 'minutes') >= 15
    },

    promote () {
      if (this.promoting) {
        return
      }

      this.$store.dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          }

          const body = {
            promoted: true
          }

          this.promoting = true
          this.$http.patch(
            `v1/users/${this.$store.state.user.id}/players/${this.itemData.id}`,
            body,
            options
          ).then(
            (response) => {
              this.promoting = false
              if (response.body && response.body.id) {
                this.promotion_status = response.body.promoted
              }
            },
            (response) => {
              this.promoting = false
              this.handleErrors(response)
            }
          )
        }
      ).catch(
        (response) => {
          this.promoting = false
          this.handleErrors(response)
        }
      )
    }
  }
}
