import moment from 'moment'

export default {
  name: 'counter',
  props: ['value'],
  data () {
    return {
      deadline: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      interval: null
    }
  },
  mounted () {
    const end = moment.utc(this.value)
    // Update the count down every 1 second
    this.timerCount(end)

    this.interval = setInterval(() => {
      this.timerCount(end)
    }, 1000)
  },

  methods: {
    timerCount (end) {
      // Get todays date and time
      const now = moment.utc()

      const passTime = end.valueOf() - now.valueOf()

      if (passTime > 0) {
        this.calcTime(passTime)
      } else {
        clearInterval(this.interval)
      }
    },
    calcTime (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.deadline.days = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.deadline.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.deadline.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
      this.deadline.seconds = Math.floor((dist % (1000 * 60)) / 1000)
    }
  }
}
