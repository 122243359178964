import get from 'lodash.get'
import PlayerFilterItem from '@/components/PlayerFilterItem/main'
import PopUp from '@/components/PopUp/main'
import filter from '@/constants/filter'
import globalHelper from '@/mixins/global'
import findIndex from 'lodash.findindex'

export default {
  name: 'filter-item',
  components: {
    PlayerFilterItem,
    PopUp
  },
  props: ['value'],
  mixins: [globalHelper],
  data () {
    return {
      all_players: null,
      canDeleteFilter: false,
      fetchingPlayers: false,
      loadingPlayers: false,
      interval: null,
      tries: 120 // 10 minutes
    }
  },
  created () {
    if (!this.item.processed && !this.interval) {
      this.interval = setInterval(() => {
        this.checkStatus()
      }, 5000)
    }
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  },
  watch: {
    value (val) {
      if (!val.processed && !this.interval) {
        this.interval = setInterval(() => {
          this.checkStatus()
        }, 5000)
      }
    }
  },
  computed: {
    item () {
      return this.value
    },
    players () {
      return this.all_players !== null ? this.all_players.filter((player) => {
        // filter by htms28 as there are slight changes it is not updated yet by the backend crons
        return !(this.item.htms28_min !== null && (player.htms28 < this.item.htms28_min || player.htms28 > this.item.htms28_max))
      }) : null
    },
    emailNotifications () {
      return get(this.value, 'settings.notifications_email', false)
    },
    pushNotifications () {
      if (this.is_push_blocked || !this.$store.state.device) {
        return false
      }

      return get(this.value, 'settings.notifications_push', false) &&
        this.$store.state.user.devices && this.$store.state.user.devices.length &&
        findIndex(this.$store.state.user.devices, ['token', this.$store.state.device]) >= 0
    },
    matchesCounter () {
      return this.players ? this.players.length : Math.min(this.item.players_count, 100)
    }
  },

  methods: {
    minMaxAttributes () {
      return filter.SCHEMA.filter(attribute => attribute.type === 'integer' || attribute.type === 'money' || attribute.type === 'range' || attribute.type === 'age')
    },

    translate (attrName, value) {
      switch (attrName) {
        case 'form':
        case 'experience':
        case 'leadership':
        case 'stamina_skill':
        case 'keeper_skill':
        case 'playmaker_skill':
        case 'scorer_skill':
        case 'passing_skill':
        case 'winger_skill':
        case 'defender_skill':
        case 'set_pieces_skill':
          return this.$te(`player.levels.skill[${value}]`) ? this.$t(`player.levels.skill[${value}]`) + ` (${value})` : value
        case 'agreeability':
          return this.$te(`player.levels.agreeability[${value}]`) ? this.$t(`player.levels.agreeability[${value}]`) + ` (${value})` : value
        case 'aggressiveness':
          return this.$te(`player.levels.aggressiveness[${value}]`) ? this.$t(`player.levels.aggressiveness[${value}]`) + ` (${value})` : value
        case 'honesty':
          return this.$te(`player.levels.honesty[${value}]`) ? this.$t(`player.levels.honesty[${value}]`) + ` (${value})` : value
        case 'age':
          // eslint-disable-next-line
          const y = Math.floor(value)
          // eslint-disable-next-line
          const d = Math.floor(value * 1000 - y * 1000)
          return (this.$te('general.years') ? this.$tc('general.years', y) : `${y}y`) + ', ' + (this.$te('general.days') ? this.$tc('general.days', d) : `${d}d`)
        case 'htms':
        case 'htms28':
        case 'tsi':
          return this.$n(value)
        case 'salary':
        case 'price':
          return this.country.currency_code
            ? this.$n(value, { style: 'currency', currency: this.country.currency_code, minimumFractionDigits: 0 })
            : this.$n(value)
        default: return value
      }
    },

    edit (event) {
      event.preventDefault()
      event.stopPropagation()

      this.$router.push({ name: 'FiltersAddEdit', params: { id: this.item.id || null } })
    },
    remove (event) {
      event.preventDefault()
      event.stopPropagation()

      // show the pop-up
      this.canDeleteFilter = true
    },
    confirmRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // perform the delete and close the tooltip
      this.canDeleteFilter = false

      this.$store.dispatch('filter_delete', this.item.id).then(
        () => {
          // remove the filter from the list
        },
        (response) => {
          this.handleErrors(response)
        }
      )
    },
    cancelRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // close popup
      this.canDeleteFilter = false
    },
    fetchPlayers (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.fetchingPlayers) {
        return
      }

      this.fetchingPlayers = true
      // reset existing players list
      this.all_players = null

      this.$store.dispatch('filter_search', this.item.id).then(
        () => {
          this.fetchingPlayers = false
        },
        (response) => {
          this.fetchingPlayers = false
          this.handleErrors(response)
        }
      )
    },
    loadPlayers (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.loadingPlayers || this.fetchingPlayers) {
        return
      }

      this.loadingPlayers = true
      this.$store.dispatch('players_get_filter', this.item.id).then(
        (response) => {
          this.loadingPlayers = false
          this.all_players = response
        },
        (response) => {
          this.loadingPlayers = false
          this.handleErrors(response)
        }
      )
    },
    removePlayer (id) {
      if (this.players) {
        this.all_players = this.all_players.filter((item) => {
          return item.id !== id
        })
      }
    },

    checkStatus () {
      this.tries--

      if (this.item.processed || this.tries <= 0) {
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = null
        }
        return
      }

      this.$store.dispatch('token_check').then(
        () => {
          const options = {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`
            }
          }

          this.$http.get(
            `v1/users/${this.$store.state.user.id}/filters/${this.item.id}/check`,
            options
          ).then(
            (response) => {
              if (response && response.body) {
                if (this.interval) {
                  clearInterval(this.interval)
                  this.interval = null
                }

                this.$http.get(
                  `v1/users/${this.$store.state.user.id}/filters/${this.item.id}`,
                  options
                ).then(
                  (response) => {
                    if (response.body) {
                      // this.internal_item = this.$helpers.transformFilterToJS(response.body)
                      const obj = this.$helpers.transformFilterToJS(response.body)
                      this.$store.commit('updateInFilters', obj)
                    }
                  },
                  () => {
                  }
                )
              }
            },
            () => {
            }
          )
        }
      ).catch(
        (response) => {
          this.tries = 0
          this.handleErrors(response)
        }
      )
    }
  }
}
