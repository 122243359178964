import PlayerItem from '@/components/PlayerItem/main'
import PopUp from '@/components/PopUp/main'
import globalHelper from '@/mixins/global'
import findIndex from 'lodash.findindex'

export default {
  name: 'player-filter-item',
  components: {
    PlayerItem,
    PopUp
  },
  props: ['itemData'],
  mixins: [globalHelper],
  data () {
    return {
      canHidePlayer: false,
      bookmarking: false
    }
  },

  computed: {
    bookmarked () {
      return this.$store.state.players_bookmarked &&
        findIndex(this.$store.state.players_bookmarked, ['id', this.itemData.id]) >= 0
    }
  },

  methods: {
    bookmark (value) {
      if (this.bookmarking) {
        return
      }

      this.bookmarking = true
      this.$store.dispatch('players_bookmark', { id: this.itemData.id, bookmarked: value }).then(
        (response) => {
          this.bookmarking = false
        },
        (response) => {
          this.bookmarking = false
          this.handleErrors(response)
        }
      )
    },
    hide () {
      // show the pop-up
      this.canHidePlayer = true
    },
    confirmRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // perform the un-bookmarking and close the tooltip
      this.canHidePlayer = false

      this.$store.dispatch('players_ignore', { id: this.itemData.id, filterId: this.itemData.pivot.filter_id, ignored: true }).then(
        (response) => {
          this.$emit('remove', this.itemData.id)
        },
        response => this.handleErrors(response)
      )
    },
    cancelRemove (event) {
      event.preventDefault()
      event.stopPropagation()

      // close popup
      this.canHidePlayer = false
    }
  }
}
